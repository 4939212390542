import { Icon } from "@iconify/react/dist/iconify.js";
import { atom } from "jotai";
import { ReactNode } from "react";

export type ModalConfirmProps = {
  isOpen: boolean;
  illustration?: ReactNode;
  title: string;
  description: string | ReactNode;
  confirmText?: string;
  confirmIcon?: ReactNode;
  onConfirm: () => void;
  cancelText?: string;
  cancelIcon?: ReactNode;
  onClose?: () => void;
  loading?: boolean;
  isDanger?: boolean;
};

export const modalconfirmValue = {
  isOpen: false,
  title: "",
  illustration: (
    <Icon
      icon="tabler:exclamation-circle-filled"
      className="w-20 h-20 font-light text-red-600"
    />
  ),
  description: "",
  confirmText: "Ya, Berikutnya",
  confirmIcon: (
    <Icon icon="tabler:check" className="w-4 h-4 font-light" />
  ),
  onConfirm: () => {},
  cancelText: "Batal",
  cancelIcon: <Icon icon="tabler:x" className="w-4 h-4 font-light" />,
  onClose: () => {},
  loading: false,
  isDanger: false,
};

const modalconfirmAtom = atom<ModalConfirmProps>(modalconfirmValue);

export default modalconfirmAtom;
